import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Layout from '../../components/motorrad/layout';
import SEO from '../../components/motorrad/seo';
import settings from '../../../settings';

const topbanner = `${settings.IMAGES_BASE_URL}/images/404-images/motorrad-404-banner.jpg`;

const Motorrad404 = () => (
  <Layout>
    <SEO title="BMW U.S Rider Academy | 404" />
    <div className="error-page">
      <LazyLoadImage effect="blur" src={topbanner} alt="BMW 404 Page" />
      <div className="container">
        <h1>404 error page</h1>
        <p>
        Feeling left out? We’ll help you get back on track – just follow one of the links below. Because BMW riders never stay still for long.
        </p>
        <div className="buttons">
          <Link to="/motorrad"><button className="btn btn-bordered">BMW U.S. Rider Academy Home</button></Link>
          <Link to="/motorrad/schools"><button className="btn btn-bordered">BMW U.S. Rider Academy Classes</button></Link>
        </div>
      </div>
    </div>
  </Layout>
);

export default Motorrad404;